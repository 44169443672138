export * from "./history";

export enum DEVICE_STATUS {
  Unspecified = 0,
  Online = 1,
  Disabled = 20,
  BeginningProvisioning = 30,
  ConfiguringGooglePlay = 40,
  ApplyingPoliciesandInstallingApplications = 50,
  Offline = 60,
  UndergoingFactoryReset = 70,
  OnboardingInProgress = 80,
  OnboardingFailed = 90,
  Onboarded = 100,
  AndroidAccountAdded = 110,
  AppsInstalled = 120,
  BrandingProcessed = 130,
  PermissionPolicyProcessed = 140,
  DevicePolicyProcessed = 150,
  DeviceSettingsProcessed = 160,
  SecurityPolicyProcessed = 170,
  PhonePolicyProcessed = 180,
  CustomSettingsProcessed = 190,
}
export interface DeviceEntity {
  groupId: string;
  groupName: string;
  name: string;
  info: DeviceInfo;
  event: DeviceEvent;
  app: DeviceApp;
  report: DeviceReport;
  timestamp: number;
  ingestionTaskTime: number;
  roomInfo: DeviceRoomInfo;
}
interface DeviceInfo {
  id: string;
  name: string;
  timezone_string: string;
  state: number;
  macAddress: string;
  hardwareInfo: HardwareInfo;
  androidBuildNumber: string;
}

export interface Preferences {
  alarm?: {
    enabled?: boolean;
    repeatDaily?: boolean;
    date?: string;
    time?: string;
    volume?: number;
    fileName?: string;
    snooze?: boolean | number;
  };
  nightMode?: {
    start?: string;
    end?: string;
    display?: string;
    weather?: boolean;
  }
}

interface DeviceEvent {
  sound: Sound;
  batteryLevel: number;
  brightness: number;
  gpsState: string;
  bluetoothState: boolean;
  wifiSSID: string;
  wifiSignal: number;
  uploadSpeed: number;
  downloadSpeed: number;
  powerSource?: POWER_SOURCE;
  ipv4Address?: string;
}

interface DeviceApp {
  packageName: string;
  version: string;
  appName: string;
}

export interface DeviceReport {
  batteryLevel: number;
  wifi_ssid: string;
  signalStrengthDbm: number | null;
  currentActiveConnection: string;
  mobileNetworkStatus: string;
}

export interface DeviceRoomInfo {
  roomNumber: string;
  macAddress: string;
  floor: string;
  checkOut?: string;
  checkIn?: string;
  guestGroupCode?: string;
  description?: string;
  internal: boolean;
  btMacAddress?: string;
}
interface HardwareInfo {
  brand: string;
  baseVersion: string;
  model: string;
  Serial: string;
}

interface Sound {
  STREAM_RING: number;
  STREAM_ALARM: number;
  STREAM_MUSIC: number;
  STREAM_NOTIFICATION: number;
}

export enum BATTERY_LEVEL {
  High = "high",
  Medium = "medium",
  Low = "low",
}

export enum ROOM_STAY_STATUS {
  Vacant = "Vacant",
  Occupied = "Occupied",
  Today = "Today",
  Tomorrow = "Tomorrow",
  Overdue = "Overdue",
  Future = "Future",
}

export enum POWER_SOURCE {
  BATTERY_UNPLUGGED = "BATTERY_UNPLUGGED",
  BATTERY_PLUGGED_USB = "BATTERY_PLUGGED_USB",
  BATTERY_PLUGGED_AC = "BATTERY_PLUGGED_AC",
}
